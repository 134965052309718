import { Button, Group } from "@mantine/core";
import Logo from "../../component/common/Logo";
import styled from "@emotion/styled";
import { notifications } from "@mantine/notifications";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { Logout } from "tabler-icons-react";

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
  padding-left: 5px;
`;

const LogoImageContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: -3px;
  max-width: 130px;
`;

export const MainHeader = () => {
  const navigate = useNavigate();
  const handleLogOut = async () => {
    try {
      await Auth.signOut();
    } catch (e: any) {
      notifications.show({
        title: "Error signing out",
        message: e?.toString(),
        color: "red",
        radius: "sm",
      });
      throw e;
    }
  };
  return (
    <Group
      style={{
        display: "flex",
        flex: 1,
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
      align="center"
    >
      <Group
        position="apart"
        mt="-3px"
        maw="1000px"
        align="center"
        style={{ display: "flex", flex: 1, width: "100%" }}
      >
        <LogoWrapper onClick={() => navigate("/")}>
          <LogoImageContainer>
            <Logo height={50} width={150} />
          </LogoImageContainer>
        </LogoWrapper>
        <Button
          leftIcon={<Logout size={18} />}
          size={"xs"}
          radius={"xs"}
          variant="filled"
          onClick={handleLogOut}
        >
          Sign Out
        </Button>
      </Group>
    </Group>
  );
};
