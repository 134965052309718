import styled from "@emotion/styled";
import { MultiSelect, Select } from "@mantine/core";

export const StyledMultiSelect = styled(MultiSelect)`
  button {
    color: #fff;
  }
  .mantine-MultiSelect-value {
    border-radius: 2px;
    background-color: #74c0fc;
    color: #fff;
  }
  .mantine-MultiSelect-values {
    padding: 5px 0;
  }
  .mantine-MultiSelect-input {
    padding-left: 10px;
    &:focus {
      border-color: #74c0fc;
      border-width: 2px;
      border-top: 0;
      border-left: 0;
      border-right: 0;
    }
    &:focus-within {
      border-color: #74c0fc;
      border-width: 2px;
    }
  }
`;

export const StyledSelect = styled(Select)`
  button {
    color: #fff;
  }
  .mantine-Select-value {
    border-radius: 2px;
    background-color: #74c0fc;
    color: #fff;
  }
  .mantine-Select-input {
    background-color: #fff;
    border: 2px solid #ced4da;
    &:focus {
      border-color: #74c0fc;
      border-width: 2px;
    }
    &:focus-within {
      border-color: #74c0fc;
      border-width: 2px;
    }
  }
`;
