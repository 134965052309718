import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

export const withPhoneHyphen = (phone: string) =>
  phone.length === 10
    ? phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
    : phone;

export const formatBdayToReadable = (birthday: string) => {
  const date = dayjs(birthday, "YYYY-MM-DD");
  return date.format("MM-DD-YYYY");
};

export const formatBdayToStore = (birthday: string) => {
  const date = dayjs(birthday, "MM-DD-YYYY");
  return date.format("YYYY-MM-DD");
};

export const paginateArray = (
  array: any[],
  page: number,
  itemsPerPage: number
): any[] => {
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return array.slice(startIndex, endIndex);
};

export const uppercaseObj = (obj: Record<string, any>): Record<string, any> => {
  const newObj: Record<string, any> = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      newObj[key] =
        typeof obj[key] === "string" ? obj[key].toUpperCase() : obj[key];
    }
  }

  return newObj;
};
