import {
  ActionIcon,
  Anchor,
  Container,
  Flex,
  Group,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { DiscountCheck, Mail, Phone } from "tabler-icons-react";
import Hippa from "../common/Hippa";

export const MainFooter = () => {
  const theme = useMantineTheme();
  return (
    <Group
      style={{
        display: "flex",
        flex: 1,
        width: "100%",
        height: "100%",
        justifyContent: "center",
      }}
      bg={theme.colors.gray[1]}
    >
      <Flex
        justify={{ xs: "flex-start", sm: "space-between" }}
        maw="1000px"
        p="xl"
        sx={{
          width: "100%",
          flexDirection: "row",
          "@media (max-width: 768px)": {
            flexDirection: "column",
          },
        }}
        h="100%"
        align={"flex-start"}
      >
        <Flex
          sx={{
            flexDirection: "row",
            width: "100%",
            flex: 1,
            justifyContent: "space-between",
            "@media (max-width: 768px)": {
              flexDirection: "column",
              margin: "20px",
            },
          }}
        >
          <Group
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 0,
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Group
              sx={{
                justifyContent: "flex-start",
              }}
            >
              <Text size="xl" fw="bold">
                About Us
              </Text>
            </Group>
            <Group
              sx={{
                gap: "3px",
              }}
            >
              <ActionIcon>
                <Phone color={theme.colors.gray[5]} size={18} />
              </ActionIcon>
              <Text size="sm" color={theme.colors.gray[7]}>
                931-221-2990
              </Text>
            </Group>
            <Group style={{ gap: "3px" }}>
              <ActionIcon>
                <Mail color={theme.colors.gray[5]} size={18} />
              </ActionIcon>
              <Text size="sm" color={theme.colors.gray[7]}>
                info@urahc.com
              </Text>
            </Group>
            <Group style={{ gap: "3px" }}>
              <ActionIcon>
                <DiscountCheck color={theme.colors.gray[5]} size={19} />
              </ActionIcon>
              <Anchor
                size="sm"
                href="https://youradvancedhealthcare.com/privacy-policy/"
                target="_blank"
                color={theme.colors.gray[7]}
                style={{ textDecoration: "underline" }}
              >
                Privacy Policy
              </Anchor>
            </Group>
          </Group>
          <Group>
            <Container
              sx={{
                width: "auto",
                display: "flex",
                marginLeft: 0,
                maxWidth: "100px",
                marginRight: 0,
                paddingRight: 0,
                marginTop: "10px",
                "@media (max-width: 768px)": {
                  marginLeft: "-10px",
                  maxWidth: "50px",
                },
              }}
            >
              <Hippa width={"100%"} height={"100%"} />
            </Container>
          </Group>
        </Flex>
      </Flex>
    </Group>
  );
};
