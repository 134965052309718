import { MantineThemeOverride } from "@mantine/core";

export const themeConfig: MantineThemeOverride = {
  fontFamily: "Lexend",
  primaryColor: "blue",
  primaryShade: 3,
  headings: { fontFamily: "Lexend" },
  components: {
    LoadingOverlay: {
      defaultProps: { size: "sm", color: "gray", variant: "bars" },
    },
    Input: {
      styles: (theme) => ({
        input: {
          textTransform: "capitalize",
          borderWidth: "2px",
          ":focus": {
            borderColor: theme.colors.blue[3],
          },
          ":focus-within": {
            borderColor: theme.colors.blue[3],
          },
        },
      }),
    },
    Textarea: {
      styles: (theme) => ({
        input: {
          textTransform: "capitalize",
          borderWidth: "2px",
          ":focus": {
            borderColor: theme.colors.blue[3],
          },
          ":focus-within": {
            borderColor: theme.colors.blue[3],
          },
        },
      }),
    },
  },
};
