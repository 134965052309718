import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
} from "@mantine/core";
import React from "react";
import { Notifications } from "@mantine/notifications";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Amplify } from "aws-amplify";
import { useState } from "react";
import { AppRoutes } from "./Routes";
import { themeConfig } from "./themeConfig";
import { bugsnagClient } from "@/bugsnag.config";

const ErrorBoundary = bugsnagClient
  ?.getPlugin("react")
  .createErrorBoundary(React);

Amplify.configure({
  Auth: {
    region: import.meta.env.VITE_AUTH_REGION,
    userPoolId: import.meta.env.VITE_AUTH_USERPOOLID,
    userPoolWebClientId: import.meta.env.VITE_AUTH_USERPOOL_WEB_CLIENTID,
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const AppCore = () => {
  const [colorScheme, setColorScheme] = useState<ColorScheme>("light");

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ColorSchemeProvider
          colorScheme={colorScheme}
          toggleColorScheme={toggleColorScheme}
        >
          <MantineProvider
            theme={{ ...themeConfig, colorScheme }}
            withGlobalStyles
            withNormalizeCSS
          >
            <AppRoutes />
            <Notifications />
          </MantineProvider>
        </ColorSchemeProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export const App = AppCore;
