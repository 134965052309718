export const commonMeds = [
  "ASA",
  "Pravastatin",
  "Atorvastatin",
  "Furosemide",
  "Spironolactone",
  "Metformin",
  "Novolog",
  "Humolog",
  "Lantus",
  "Lisinopril",
  "Carvedilol",
  "Nitrostat",
  "Clonidine",
  "Hydralazine",
  "HCTZ",
  "Albuterol",
  "Famotidine",
  "Pantoprazole",
  "Metoprolol",
  "Amlodipine",
  "Eliquis",
];

export const commonAllergies = [
  "PCN",
  "NSAIDs",
  "Iodine",
  "Shellfish",
  "NKDA",
  "Sulfa",
];

export const commonConditions = [
  "Chronic Kidney Disease",
  "HTN",
  "GERD",
  "DM",
  "Hyperlipidemia",
  "Dyslipidemia",
  "CHF",
  "COPD",
  "Arteriosclerosis",
  "Neuropathy",
  "Chronic Ischemic Heart Disease",
];

export const commonRisks = [
  "None",
  "InCenter HD",
  "Home HD",
  "PD",
  "Pacemaker",
  "AICD",
  "Home O2",
];
