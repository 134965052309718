import styled from "@emotion/styled";
import {
  Button,
  Checkbox,
  Container,
  Divider,
  Grid,
  Group,
  InputBase,
  Radio,
  Text,
  TextInput,
  Textarea,
  ThemeIcon,
  rem,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import InputMask from "react-input-mask";

import { useAuth } from "@/auth";
import { commonRisks } from "@/configs";
import {
  ActivityHeartbeat,
  BuildingHospital,
  Check,
  CreditCard,
  Lungs,
  Notes,
  ScaleOutline,
  Signature,
  User,
} from "tabler-icons-react";
import { formatBdayToStore } from "../common";
import { StyledMultiSelect, StyledSelect } from "../common/select";

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  padding-left: 0;
`;

function AddPatient() {
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [viewDevicesForm, setViewDevicesForm] = useState(false);
  const [bpChecked, setBpChecked] = useState(false);
  const [scaleChecked, setScaleChecked] = useState(false);
  const [oximeterChecked, setOximeterChecked] = useState(false);
  const [deviceProvidedBy, setDeviceProvidedBy] = useState("");
  const [bpImei, setBpImei] = useState<string>("");
  const [scaleImei, setScaleImei] = useState("");
  const [oximeterImei, setOximeterImei] = useState("");
  const user: any = useAuth().currentUser;
  const theme = useMantineTheme();

  const setProvider = () => {
    if (user?.attributes?.["custom:Provider"]) {
      return user?.attributes?.["custom:Provider"];
    }
    return "Unknown";
  };

  useEffect(() => {
    setProvider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const patientInfoForm = useForm({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      prescriber: "",
      provider: user?.attributes?.["custom:Provider"] || "Unknown",
      street: "",
      street2: "",
      birthday: "",
      city: "",
      state: "Tennessee",
      zip: "",
      gender: "",
      phone: "",
      diagnosisCode: "",
      risks: [],
      ehrIdentifier: "",
      providerEmail: "",
      providerPhone: "",
      insuranceName: "",
      insuranceId: "",
      insuranceName2: "",
      insuranceId2: "",
      signature: "",
      notation: "",
    },

    validate: {
      firstName: (value) =>
        value.length < 2 ? "First name must have at least 2 letters" : null,
      lastName: (value) =>
        value.length < 2 ? "Last name must have at least 2 letters" : null,
      birthday: (value) => (value.length < 2 ? "Birthday must be valid" : null),
      prescriber: (value) =>
        value.length < 2 ? "Prescriber must be valid" : null,
      provider: (value) => (value.length < 2 ? "Provider must be valid" : null),
      signature: (value) =>
        value.length < 2 ? "Signature must be provided" : null,
      street: (value) => (value.length < 5 ? "Street must be provided" : null),
      city: (value) => (value.length < 2 ? "City must be provided" : null),
      state: (value) => (value.length < 2 ? "State must be provided" : null),
      zip: (value) => (value.length < 5 ? "Zip must be provided" : null),
      gender: (value) => (value.length < 4 ? "Gender must be provided" : null),
    },
  });
  const checkIcon = <Check style={{ width: rem(20), height: rem(20) }} />;

  const formatDevices = () => {
    const devices = [];
    if (bpChecked) {
      devices.push({
        type: "BloodPressure",
        imei: bpImei && deviceProvidedBy === "provider" ? bpImei : "",
      });
    }
    if (scaleChecked) {
      devices.push({
        type: "Weight",
        imei: scaleImei && deviceProvidedBy === "provider" ? scaleImei : "",
      });
    }
    if (oximeterChecked) {
      devices.push({
        type: "SpO2",
        imei:
          oximeterImei && deviceProvidedBy === "provider" ? oximeterImei : "",
      });
    }
    return devices;
  };

  const handleSavePatient = async (event: any) => {
    event.preventDefault();

    setLoadingAdd(true);
    try {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      let birthdayFormatted;
      if (patientInfoForm.values.birthday) {
        birthdayFormatted = formatBdayToStore(patientInfoForm.values.birthday);
      }

      const response = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/referral/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ...patientInfoForm.values,
            birthday: birthdayFormatted,
            devices: formatDevices(),
            deviceProvider: deviceProvidedBy,
          }),
        }
      );
      if (!response.ok) {
        throw new Error(response.statusText);
      } else {
        notifications.show({
          title: "Success",
          icon: checkIcon,
          message: "Referral submitted",
          color: "green",
          radius: "xs",
        });
        setLoadingAdd(false);
        patientInfoForm.reset();
        setBpImei("");
        setScaleImei("");
        setOximeterImei("");
        setProvider();
        setBpChecked(false);
        setScaleChecked(false);
        setOximeterChecked(false);
      }
    } catch (e: any) {
      setLoadingAdd(false);
      notifications.show({
        title: "Error sending referral",
        message: e?.toString(),
        color: "red",
        radius: "md",
      });
      throw e;
    }
  };

  const handleBpImei = (val: string) => {
    const numericValue = val.replace(/[^0-9]/g, "");
    setBpImei(numericValue);
  };

  const handleScaleImei = (val: string) => {
    const numericValue = val.replace(/[^0-9]/g, "");
    setScaleImei(numericValue);
  };

  const handleOximeterImei = (val: string) => {
    const numericValue = val.replace(/[^0-9]/g, "");
    setOximeterImei(numericValue);
  };

  return (
    <Container
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        maxWidth: "1000px",
        width: "100%",
        paddingTop: "10px",
      }}
    >
      <Text fw={"bold"} size={"23px"} color={theme.colors.gray[8]}>
        Patient Referral Form
      </Text>
      <Text size={"15px"} maw={"600px"} color={theme.colors.gray[6]}>
        Please provide as much information as possible. Fields with (*) are
        required. If you have questions or need assistance reach out to us at
        support@urahc.com.
      </Text>
      <form
        id="patientForm"
        onSubmit={handleSavePatient}
        style={{
          paddingBottom: "50px",
          paddingTop: "25px",
          width: "100%",
          flex: 1,
          flexDirection: "column",
          alignSelf: "center",
          justifyContent: "center",
        }}
      >
        <Group style={{ maxWidth: "none" }} mb="xs">
          <ThemeIcon
            size={"md"}
            radius={"xl"}
            sx={(theme) => ({
              backgroundColor: theme.colors.blue[3],
              marginRight: "-5px",
            })}
          >
            <User size={18} />
          </ThemeIcon>
          <Text mr="xs" size="lg" fw={"bold"} color={theme.colors.gray[8]}>
            Patient Info
          </Text>
        </Group>
        <Grid mb={0} mt={"-5px"} grow>
          <Grid.Col sm={3} lg={3}>
            <TextInput
              radius={"xs"}
              size="sm"
              required
              label={
                <Text span>
                  First Name{" "}
                  <Text span c="red.6">
                    (required)
                  </Text>
                </Text>
              }
              placeholder="First Name"
              {...patientInfoForm.getInputProps("firstName")}
            />
          </Grid.Col>
          <Grid.Col sm={3} lg={3}>
            <TextInput
              radius={"xs"}
              size="sm"
              label="Middle Name"
              placeholder="Middle Name"
              {...patientInfoForm.getInputProps("middleName")}
            />
          </Grid.Col>
          <Grid.Col sm={3} lg={3}>
            <TextInput
              radius={"xs"}
              size="sm"
              required
              label={
                <Text span>
                  Last Name{" "}
                  <Text span c="red.6">
                    (required)
                  </Text>
                </Text>
              }
              placeholder="Last Name"
              {...patientInfoForm.getInputProps("lastName")}
            />
          </Grid.Col>
        </Grid>
        <Grid mb={0} mt={"-5px"} grow>
          <Grid.Col sm={3} lg={3}>
            <InputBase
              label={
                <Text span>
                  Birthday{" "}
                  <Text span c="red.6">
                    (required)
                  </Text>
                </Text>
              }
              radius={"xs"}
              size="sm"
              required
              placeholder="MM/DD/YYYY"
              component={InputMask}
              mask="99/99/9999"
              {...patientInfoForm.getInputProps("birthday")}
            />
          </Grid.Col>
          <Grid.Col sm={3} lg={3}>
            <InputBase
              label={
                <Text span>
                  Phone{" "}
                  <Text span c="red.6">
                    (required)
                  </Text>
                </Text>
              }
              radius={"xs"}
              size="sm"
              required
              placeholder="Phone (required)"
              component={InputMask}
              mask="999-999-9999"
              {...patientInfoForm.getInputProps("phone")}
            />
          </Grid.Col>
          <Grid.Col sm={3} lg={3}>
            <StyledSelect
              radius="xs"
              size="sm"
              label={
                <Text span>
                  Gender{" "}
                  <Text span c="red.6">
                    (required)
                  </Text>
                </Text>
              }
              required
              styles={{
                input: {
                  textTransform: "none",
                },
              }}
              variant="filled"
              placeholder="Select Gender"
              {...patientInfoForm.getInputProps("gender")}
              data={["Male", "Female", "Unknown"]}
            />
          </Grid.Col>
        </Grid>
        <Grid mb={0} mt={"-5px"} grow>
          <Grid.Col sm={3} lg={3}>
            <TextInput
              radius={"xs"}
              size="sm"
              required
              label={
                <Text span>
                  Street Address{" "}
                  <Text span c="red.6">
                    (required)
                  </Text>
                </Text>
              }
              placeholder="Street Address"
              {...patientInfoForm.getInputProps("street")}
            />
          </Grid.Col>
          <Grid.Col sm={3} lg={3}>
            <TextInput
              radius={"xs"}
              size="sm"
              label="Apt, Suite, etc."
              placeholder="Apartment, Suite, etc."
              {...patientInfoForm.getInputProps("street2")}
            />
          </Grid.Col>
        </Grid>
        <Grid mb={0} mt={"-5px"} grow>
          <Grid.Col sm={3} lg={3}>
            <TextInput
              radius={"xs"}
              size="sm"
              required
              label={
                <Text span>
                  City{" "}
                  <Text span c="red.6">
                    (required)
                  </Text>
                </Text>
              }
              placeholder="City"
              {...patientInfoForm.getInputProps("city")}
            />
          </Grid.Col>
          <Grid.Col sm={3} lg={3}>
            <TextInput
              radius={"xs"}
              size="sm"
              required
              label={
                <Text span>
                  State{" "}
                  <Text span c="red.6">
                    (required)
                  </Text>
                </Text>
              }
              placeholder="State"
              {...patientInfoForm.getInputProps("state")}
            />
          </Grid.Col>
          <Grid.Col sm={3} lg={3}>
            <TextInput
              radius={"xs"}
              size="sm"
              required
              label={
                <Text span>
                  Zip{" "}
                  <Text span c="red.6">
                    (required)
                  </Text>
                </Text>
              }
              placeholder="Zip Code"
              {...patientInfoForm.getInputProps("zip")}
            />
          </Grid.Col>
        </Grid>
        <Grid mb={0} mt={"-5px"} grow>
          <Grid.Col sm={3} lg={3}>
            <TextInput
              radius={"xs"}
              size="sm"
              label="ICD-10 Diagnosis Codes"
              placeholder="Comma Separated Codes"
              {...patientInfoForm.getInputProps("diagnosisCode")}
            />
          </Grid.Col>
          <Grid.Col sm={3} lg={3}>
            <TextInput
              radius={"xs"}
              size="sm"
              label="EHR/EMR Identifier"
              placeholder="EHR/EMR Identifier"
              {...patientInfoForm.getInputProps("ehrIdentifier")}
            />
          </Grid.Col>
        </Grid>
        <Grid mb={0} mt={"-5px"}>
          <Grid.Col sm={3} lg={3}>
            <StyledMultiSelect
              data={[...commonRisks]}
              size="sm"
              radius={"xs"}
              label="Other Risks"
              placeholder="Select Risks"
              {...patientInfoForm.getInputProps("risks")}
            />
          </Grid.Col>
        </Grid>
        <Group style={{ maxWidth: "none" }} mb="xs" mt="lg">
          <ThemeIcon
            size={"md"}
            radius={"xl"}
            sx={(theme) => ({
              backgroundColor: theme.colors.blue[3],
              marginRight: "-5px",
            })}
          >
            <BuildingHospital size={18} />
          </ThemeIcon>
          <Text mr="xs" size="lg" fw={"bold"} color={theme.colors.gray[8]}>
            Provider Info
          </Text>
        </Group>
        <Grid mb={0} mt={"-5px"} grow>
          <Grid.Col sm={3} lg={3}>
            <TextInput
              radius={"xs"}
              size="sm"
              required
              label={
                <Text span>
                  Referring Doctor{" "}
                  <Text span c="red.6">
                    (required)
                  </Text>
                </Text>
              }
              placeholder="Referring Doctor"
              {...patientInfoForm.getInputProps("prescriber")}
            />
          </Grid.Col>
          <Grid.Col sm={3} lg={3}>
            <TextInput
              radius="xs"
              size="sm"
              label={
                <Text span>
                  Referring Clinic{" "}
                  <Text span c="red.6">
                    (required)
                  </Text>
                </Text>
              }
              required
              {...patientInfoForm.getInputProps("provider")}
              placeholder="Referring Clinic"
            />
          </Grid.Col>
        </Grid>
        <Group style={{ maxWidth: "none" }} mb="xs" mt="lg">
          <ThemeIcon
            size={"md"}
            radius={"xl"}
            sx={(theme) => ({
              backgroundColor: theme.colors.blue[3],
              marginRight: "-5px",
            })}
          >
            <CreditCard size={18} />
          </ThemeIcon>
          <Text mr="xs" size="lg" fw={"bold"} color={theme.colors.gray[8]}>
            Insurance
          </Text>
        </Group>
        <Grid mb={0} mt={"-5px"} grow>
          <Grid.Col sm={3} lg={3}>
            <TextInput
              radius={"xs"}
              size="sm"
              label="Primary Ins. Name"
              placeholder="Primary Ins."
              {...patientInfoForm.getInputProps("insuranceName")}
            />
          </Grid.Col>
          <Grid.Col sm={3} lg={3}>
            <TextInput
              radius={"xs"}
              size="sm"
              label="Primary Ins. Id"
              placeholder="Primary Ins. Id"
              {...patientInfoForm.getInputProps("insuranceId")}
            />
          </Grid.Col>
        </Grid>
        <Grid mb={0} mt={"-5px"} grow>
          <Grid.Col sm={3} lg={3}>
            <TextInput
              radius={"xs"}
              size="sm"
              label="Secondary Ins. Name"
              placeholder="Secondary Ins."
              {...patientInfoForm.getInputProps("insuranceName2")}
            />
          </Grid.Col>
          <Grid.Col sm={3} lg={3}>
            <TextInput
              radius={"xs"}
              size="sm"
              label="Secondary Ins. Id"
              placeholder="Secondary Ins. Id"
              {...patientInfoForm.getInputProps("insuranceId2")}
            />
          </Grid.Col>
        </Grid>
        <Group style={{ maxWidth: "none" }} mb="xs" mt="lg">
          <ThemeIcon
            size={"md"}
            radius={"xl"}
            sx={(theme) => ({
              backgroundColor: theme.colors.blue[3],
              marginRight: "-5px",
            })}
          >
            <ScaleOutline size={18} />
          </ThemeIcon>
          <Text mr="xs" size="lg" fw={"bold"} color={theme.colors.gray[8]}>
            Devices
          </Text>
        </Group>

        <Group>
          <Text size={"14px"} color={theme.colors.gray[9]}>
            1. Select all devices that patient is requiring{" "}
            <Text span c="red.6">
              (required)
            </Text>
          </Text>
        </Group>
        <Grid mb={0} mt={"-5px"}>
          <Grid.Col sm={3} lg={3}>
            <Group
              onClick={() => setBpChecked(!bpChecked)}
              sx={{
                padding: "8px",
                cursor: "pointer",
                userSelect: "none",
                border: `2px solid ${
                  bpChecked ? theme.colors.blue[3] : "#ced4da"
                }`,
              }}
            >
              <Checkbox
                checked={bpChecked}
                size="sm"
                radius="xs"
                sx={{ cursor: "pointer", userSelect: "none" }}
                onChange={(event) => setBpChecked(event.currentTarget.checked)}
              />
              <Text size="sm" pl="0" ml={"-6px"}>
                Blood Pressure
              </Text>
            </Group>
          </Grid.Col>
          <Grid.Col sm={3} lg={3}>
            <Group
              onClick={() => setScaleChecked(!scaleChecked)}
              sx={{
                padding: "8px",
                cursor: "pointer",
                userSelect: "none",
                border: `2px solid ${
                  scaleChecked ? theme.colors.blue[3] : "#ced4da"
                }`,
              }}
            >
              <Checkbox
                checked={scaleChecked}
                size="sm"
                radius="xs"
                sx={{ cursor: "pointer", userSelect: "none" }}
                onChange={(event) =>
                  setScaleChecked(event.currentTarget.checked)
                }
              />
              <Text size="sm" pl="0" ml={"-6px"}>
                Scale
              </Text>
            </Group>
          </Grid.Col>
          <Grid.Col sm={3} lg={3}>
            <Group
              onClick={() => setOximeterChecked(!oximeterChecked)}
              sx={{
                padding: "8px",
                cursor: "pointer",
                userSelect: "none",
                border: `2px solid ${
                  oximeterChecked ? theme.colors.blue[3] : "#ced4da"
                }`,
              }}
            >
              <Checkbox
                checked={oximeterChecked}
                size="sm"
                radius="xs"
                sx={{ cursor: "pointer", userSelect: "none" }}
                onChange={(event) =>
                  setOximeterChecked(event.currentTarget.checked)
                }
              />
              <Text size="sm" pl="0" ml={"-6px"}>
                Oximeter
              </Text>
            </Group>
          </Grid.Col>
        </Grid>
        {(bpChecked || scaleChecked || oximeterChecked) && (
          <Grid mb={0} mt={"-5px"} grow>
            <Grid.Col sm={3} lg={3}>
              <Radio.Group
                value={deviceProvidedBy}
                label="2. Are you providing the Patient with the devices?"
                onChange={(event) => {
                  setDeviceProvidedBy(event);
                  event === "provider"
                    ? setViewDevicesForm(true)
                    : setViewDevicesForm(false);
                }}
              >
                <Group mt="xs">
                  <Radio value="provider" label="I am providing it" />
                  <Radio
                    value="ahc"
                    label="Advanced Health Care is providing it"
                  />
                </Group>
              </Radio.Group>
            </Grid.Col>
          </Grid>
        )}
        {viewDevicesForm &&
          (bpChecked || scaleChecked || oximeterChecked) &&
          deviceProvidedBy && (
            <>
              <Group mt="sm" mb="5px">
                <Text size={"14px"} color={theme.colors.gray[9]}>
                  3. Insert the IMEI of the devices
                </Text>
              </Group>
              <Grid mb={0} mt={"-5px"}>
                {bpChecked && (
                  <Grid.Col sm={3} lg={3}>
                    <TextInput
                      radius={"xs"}
                      size="sm"
                      value={bpImei}
                      onChange={(event) => handleBpImei(event.target.value)}
                      icon={
                        <ActivityHeartbeat
                          size={22}
                          color={bpImei ? theme.colors.blue[3] : "#adb5bd"}
                        />
                      }
                      minLength={5}
                      maxLength={18}
                      placeholder="Blood Pressure IMEI"
                    />
                  </Grid.Col>
                )}
                {scaleChecked && (
                  <Grid.Col sm={3} lg={3}>
                    <TextInput
                      radius={"xs"}
                      size="sm"
                      value={scaleImei}
                      onChange={(event) => handleScaleImei(event.target.value)}
                      icon={
                        <ScaleOutline
                          size={22}
                          color={scaleImei ? theme.colors.blue[3] : "#adb5bd"}
                        />
                      }
                      minLength={5}
                      maxLength={18}
                      placeholder="Scale IMEI"
                    />
                  </Grid.Col>
                )}
                {oximeterChecked && (
                  <Grid.Col sm={3} lg={3}>
                    <TextInput
                      radius={"xs"}
                      size="sm"
                      value={oximeterImei}
                      onChange={(event) =>
                        handleOximeterImei(event.target.value)
                      }
                      icon={
                        <Lungs
                          size={22}
                          color={
                            oximeterImei ? theme.colors.blue[3] : "#adb5bd"
                          }
                        />
                      }
                      minLength={5}
                      maxLength={18}
                      placeholder="Oximeter IMEI"
                    />
                  </Grid.Col>
                )}
              </Grid>
            </>
          )}
        <Group style={{ maxWidth: "none" }} mb="xs" mt="lg">
          <ThemeIcon
            size={"md"}
            radius={"xl"}
            sx={(theme) => ({
              backgroundColor: theme.colors.blue[3],
              marginRight: "-5px",
            })}
          >
            <Notes size={18} />
          </ThemeIcon>
          <Group>
            <Text mr="xs" size="lg" fw={"bold"} color={theme.colors.gray[8]}>
              Notation
            </Text>
          </Group>
        </Group>
        <Grid mb={0} mt={"-8px"} grow maw={"500px"}>
          <Grid.Col sm={3} lg={3}>
            <Textarea
              radius={"xs"}
              styles={{
                input: {
                  textTransform: "none",
                },
              }}
              size="sm"
              label="Add any additional notes for the Care Manager"
              placeholder="Notation"
              {...patientInfoForm.getInputProps("notation")}
            />
          </Grid.Col>
        </Grid>
        <Group style={{ maxWidth: "none" }} mb="xs" mt="lg">
          <ThemeIcon
            size={"md"}
            radius={"xl"}
            sx={(theme) => ({
              backgroundColor: theme.colors.blue[3],
              marginRight: "-5px",
            })}
          >
            <Signature size={18} />
          </ThemeIcon>
          <Group>
            <Text mr="xs" size="lg" fw={"bold"} color={theme.colors.gray[8]}>
              Signature
            </Text>
          </Group>
        </Group>
        <Grid mb={0} mt={"-8px"} grow maw={"500px"}>
          <Grid.Col sm={3} lg={3}>
            <TextInput
              radius={"xs"}
              size="sm"
              required
              label={
                <Text span>
                  Signature - Print your full name{" "}
                  <Text span c="red.6">
                    (required)
                  </Text>
                </Text>
              }
              placeholder="Signature"
              {...patientInfoForm.getInputProps("signature")}
            />
          </Grid.Col>
        </Grid>
        <Group style={{ maxWidth: "none" }} mb="xs">
          <Group>
            <Text ml={"xs"} size={"12px"} color={theme.colors.gray[6]}>
              You hereby confirm that you are authorized to refer this patient
              to Advanced Health Care.
            </Text>
          </Group>
        </Group>
      </form>
      <Divider variant="dashed" style={{ marginTop: 0, marginBottom: 0 }} />
      <Footer>
        <Button
          loading={loadingAdd}
          form="patientForm"
          leftIcon={<Check size={20} />}
          type="submit"
          mb="xl"
          disabled={
            !patientInfoForm.isValid() ||
            loadingAdd ||
            (!bpChecked && !scaleChecked && !oximeterChecked)
          }
          radius={"xs"}
          size="sm"
        >
          <Text size={"sm"}>Submit Referral</Text>
        </Button>
      </Footer>
    </Container>
  );
}

export default AddPatient;
