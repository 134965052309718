import styled from "@emotion/styled";
import {
  Anchor,
  Button,
  Container,
  Group,
  PasswordInput,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import Logo from "../component/common/Logo";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";

enum AuthState {
  SignIn,
  ConfirmSignUp,
  ResetPassword,
}

const SignInContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 50px;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
`;

const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  max-width: 400px;
`;

export const CustomLogin = () => {
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [passwordUpdateLoading, setPasswordUpdateLoading] = useState(false);
  const [resendCodeLoading, setResendCodeLoading] = useState(false);
  const [visible, { toggle }] = useDisclosure(false);
  const [authState, setAuthState] = useState<AuthState>(AuthState.SignIn);
  const [user, setUser] = useState<any>();

  const [requireName, setRequireName] = useState<boolean>(false);

  let navigate = useNavigate();
  const theme = useMantineTheme();
  const signIn = async () => {
    const username = signInForm.values.username;
    const password = signInForm.values.password;
    try {
      const user = await Auth.signIn(username, password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        if (user?.challengeParam?.requiredAttributes?.includes("name")) {
          setRequireName(true);
        }
        setUser(user);
        setAuthState(AuthState.ConfirmSignUp);
        setLoadingAdd(false);
      } else {
        navigate("/");
      }
    } catch (error: any) {
      if (error.code === "PasswordResetRequiredException") {
        await Auth.forgotPassword(username);
        setLoadingAdd(false);
        setAuthState(AuthState.ResetPassword);
      } else {
        setLoadingAdd(false);
        throw error;
      }
    }
  };

  const resendCode = async () => {
    setResendCodeLoading(true);
    const username = signInForm.values.username;
    try {
      await Auth.forgotPassword(username);
      setResendCodeLoading(false);
    } catch (error: any) {
      setResendCodeLoading(false);
      throw error;
    }
  };

  const completeNewPassword = async () => {
    const newPassword = confirmNewPasswordForm.values.password;
    const requiredAttributes = requireName
      ? { name: confirmNewPasswordForm.values.name }
      : {};
    try {
      await Auth.completeNewPassword(user, newPassword, requiredAttributes);
      navigate("/");
    } catch (error) {
      console.log("error signing in", error);
      throw error;
    }
  };

  const signInForm = useForm({
    initialValues: {
      username: "",
      password: "",
    },
    validate: {
      username: (value) =>
        value.length < 2 ? "Username must have at least 2 letters" : null,
      password: (value) =>
        value.length < 2 ? "Password must have at least 2 characters" : null,
    },
  });

  const confirmNewPasswordForm = useForm({
    initialValues: {
      password: "",
      confirmPassword: "",
      name: "",
    },
    validate: {
      password: (value) =>
        value.length < 6 ? "Password must have at least 8 characters" : null,
      confirmPassword: (value, values) =>
        value !== values.password ? "Passwords did not match" : null,
      name: (value) =>
        value.length < 2 ? "Name must have at least 2 characters" : null,
    },
  });

  const forgotPasswordForm = useForm({
    initialValues: {
      password: "",
      confirmPassword: "",
      code: "",
    },
    validate: {
      password: (value) =>
        value.length < 6 ? "Password must have at least 8 characters" : null,
      confirmPassword: (value, values) =>
        value !== values.password ? "Passwords did not match" : null,
    },
  });

  const signUpNewPassword = async (event: any) => {
    event.preventDefault();
    setPasswordUpdateLoading(true);
    try {
      await completeNewPassword();
    } catch (e: any) {
      setPasswordUpdateLoading(false);
      confirmNewPasswordForm.setValues({
        password: "",
        confirmPassword: "",
      });
      notifications.show({
        title: "Error updating password",
        message: e?.toString(),
        color: "red",
        radius: "md",
      });
      throw e;
    }
  };

  const handleSignIn = async (event: any) => {
    event.preventDefault();
    setLoadingAdd(true);
    try {
      await signIn();
    } catch (e: any) {
      setLoadingAdd(false);
      notifications.show({
        title: "Error Signing In",
        message: e?.message?.toString() || "Unable to sign in",
        color: "red",
        radius: "md",
      });
      throw e;
    }
  };

  const handleNewPassword = async (event: any) => {
    event.preventDefault();
    setPasswordUpdateLoading(true);
    try {
      await Auth.forgotPasswordSubmit(
        signInForm.values.username,
        forgotPasswordForm.values.code,
        forgotPasswordForm.values.password
      );
      notifications.show({
        title: "Password Updated",
        message:
          "Your password has been updated. Please sign in with new password.",
        color: "green",
        radius: "md",
      });

      signInForm.reset();
      forgotPasswordForm.reset();
      setAuthState(AuthState.SignIn);
    } catch (e: any) {
      setPasswordUpdateLoading(false);
      forgotPasswordForm.setValues({
        password: "",
        confirmPassword: "",
        code: "",
      });
      notifications.show({
        title: "Error updating password",
        message: e?.toString(),
        color: "red",
        radius: "md",
      });
      throw e;
    }
  };

  return (
    <SignInContainer>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          maxWidth: "300px",
          marginBottom: "2rem",
          alignItems: "center",
        }}
      >
        <Logo width={"45vw"} />
      </Container>
      {authState === AuthState.SignIn && (
        <Form id="signInForm" onSubmit={handleSignIn}>
          <Group spacing="lg" style={{ marginBottom: "12px" }} grow>
            <TextInput
              radius={"xs"}
              size="md"
              styles={{
                input: {
                  textTransform: "none",
                },
              }}
              required
              label="Username"
              placeholder="Username"
              {...signInForm.getInputProps("username")}
            />
          </Group>
          <Group spacing="lg" style={{ marginBottom: "12px" }} grow>
            <PasswordInput
              visible={visible}
              onVisibilityChange={toggle}
              radius={"xs"}
              styles={{
                input: {
                  textTransform: "none",
                },
              }}
              size="md"
              required
              label="Password"
              placeholder="Password"
              {...signInForm.getInputProps("password")}
            />
          </Group>
          <Group grow style={{ marginTop: "1rem" }}>
            <Button
              disabled={!signInForm.isValid()}
              loading={loadingAdd}
              type="submit"
              onClick={handleSignIn}
              radius={"sm"}
              size="md"
            >
              <Text size={"sm"}>Sign In</Text>
            </Button>
          </Group>
          <Group grow style={{ marginTop: "1rem" }}>
            <Text size={"sm"} color={theme.colors.gray[6]}>
              By signing in you are agreeing to our{" "}
              <Anchor
                color={theme.colors.gray[6]}
                href="https://youradvancedhealthcare.com/privacy-policy/"
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                terms - conditions and our privacy policy.
              </Anchor>{" "}
              Trouble signing in? Please reach out to support at
              support@urahc.com
            </Text>
          </Group>
        </Form>
      )}
      {authState === AuthState.ConfirmSignUp && (
        <Form id="confirmSignUpForm" onSubmit={signUpNewPassword}>
          {requireName && (
            <Group spacing="lg" style={{ marginBottom: "12px" }} grow>
              <TextInput
                type="text"
                radius={"xs"}
                size="md"
                required
                label="Input name"
                styles={{
                  input: {
                    textTransform: "capitalize",
                  },
                }}
                placeholder="First Last"
                {...confirmNewPasswordForm.getInputProps("name")}
              />
            </Group>
          )}

          <Group spacing="lg" style={{ marginBottom: "12px" }} grow>
            <PasswordInput
              radius={"xs"}
              size="md"
              required
              styles={{
                input: {
                  textTransform: "none",
                },
              }}
              label="New Password"
              placeholder="New Password"
              {...confirmNewPasswordForm.getInputProps("password")}
            />
          </Group>
          <Group spacing="lg" style={{ marginBottom: "12px" }} grow>
            <PasswordInput
              radius={"xs"}
              size="md"
              required
              styles={{
                input: {
                  textTransform: "none",
                },
              }}
              label="Confirm New Password"
              placeholder="Confirm New Password"
              {...confirmNewPasswordForm.getInputProps("confirmPassword")}
            />
          </Group>
          <Group grow style={{ marginTop: "1rem" }}>
            <Button
              disabled={!confirmNewPasswordForm.isValid()}
              type="submit"
              onClick={signUpNewPassword}
              loading={passwordUpdateLoading}
              radius={"sm"}
              size="md"
            >
              <Text size={"sm"}>Change Password</Text>
            </Button>
          </Group>
          <Group grow style={{ marginTop: "1rem" }}>
            <Text size={"sm"} color={theme.colors.gray[6]}>
              By signing in you are agreeing to our{" "}
              <Anchor
                color={theme.colors.gray[6]}
                href="https://youradvancedhealthcare.com/privacy-policy/"
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                terms - conditions and our privacy policy.
              </Anchor>{" "}
              Trouble signing in? Please reach out to support at
              support@urahc.com
            </Text>
          </Group>
        </Form>
      )}
      {authState === AuthState.ResetPassword && (
        <Form id="resetForm" onSubmit={handleNewPassword}>
          <Text fw={"bold"} size={"23px"} color={theme.colors.gray[8]}>
            Reset Password
          </Text>
          <Text size={"15px"} maw={"600px"} color={theme.colors.gray[6]}>
            A code has been sent to your email. Please enter the code below
            along with the new password. If you've not received the code, please
            check your spam folder or request a new code to be sent below.
          </Text>
          <Group spacing="lg" mt="lg" style={{ marginBottom: "12px" }} grow>
            <TextInput
              radius={"xs"}
              size="md"
              required
              styles={{
                input: {
                  textTransform: "none",
                },
              }}
              label="Code"
              placeholder="New Code"
              {...forgotPasswordForm.getInputProps("code")}
            />
          </Group>
          <Group spacing="lg" style={{ marginBottom: "12px" }} grow>
            <PasswordInput
              visible={visible}
              onVisibilityChange={toggle}
              radius={"xs"}
              size="md"
              required
              styles={{
                input: {
                  textTransform: "none",
                },
              }}
              label="New Password"
              placeholder="New Password"
              {...forgotPasswordForm.getInputProps("password")}
            />
          </Group>
          <Group spacing="lg" style={{ marginBottom: "12px" }} grow>
            <PasswordInput
              visible={visible}
              onVisibilityChange={toggle}
              radius={"xs"}
              size="md"
              required
              label="Confirm New Password"
              styles={{
                input: {
                  textTransform: "none",
                },
              }}
              placeholder="Confirm New Password"
              {...forgotPasswordForm.getInputProps("confirmPassword")}
            />
          </Group>
          <Group grow style={{ marginTop: "1rem" }}>
            <Button
              disabled={
                passwordUpdateLoading ||
                resendCodeLoading ||
                !forgotPasswordForm.isValid()
              }
              type="submit"
              onClick={handleNewPassword}
              loading={passwordUpdateLoading}
              radius={"sm"}
              size="md"
            >
              <Text size={"sm"}>Change Password</Text>
            </Button>
          </Group>
          <Group grow style={{ marginTop: "1rem" }}>
            <Button
              disabled={resendCodeLoading || passwordUpdateLoading}
              type="submit"
              onClick={resendCode}
              loading={resendCodeLoading}
              radius={"sm"}
              size="md"
            >
              <Text size={"sm"}>Resend Code</Text>
            </Button>
          </Group>
          <Group grow style={{ marginTop: "1rem" }}>
            <Text size={"sm"} color={theme.colors.gray[6]}>
              By signing in you are agreeing to our{" "}
              <Anchor
                color={theme.colors.gray[6]}
                href="https://youradvancedhealthcare.com/privacy-policy/"
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                terms - conditions and our privacy policy.
              </Anchor>{" "}
              Trouble signing in? Please reach out to support at
              support@urahc.com
            </Text>
          </Group>
        </Form>
      )}
    </SignInContainer>
  );
};
