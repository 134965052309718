import { Navigate } from "react-router-dom";
import useAuth from "./useAuth";

type Props = {
  children: JSX.Element;
};

export const RequireAuth = ({ children }: Props) => {
  const { currentUser, loading } = useAuth();

  if (!loading && currentUser === null) {
    return <Navigate to="/login" replace />;
  } else {
    return children;
  }
};
