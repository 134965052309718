import { AppShell, Header, Footer } from "@mantine/core";
import { Outlet } from "react-router-dom";
import { MainHeader } from "../../component/navigation/Header";
import { MainFooter } from "@/component/navigation/MainFooter";

export function Shell() {
  return (
    <AppShell
      padding="md"
      pb={"0px"}
      fixed={false}
      footer={
        <Footer
          withBorder
          height={{
            xs: "220px",
            sm: "180px",
            md: "180px",
            lg: "180px",
            xl: "180px",
          }}
        >
          <MainFooter />
        </Footer>
      }
      header={
        <Header
          sx={{
            ".mantine-Header-root": {
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              backgroundColor: "#888",
            },
          }}
          height={60}
          p="xs"
          styles={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {<MainHeader />}
        </Header>
      }
      styles={(theme) => ({
        main: {
          overflowX: "hidden",
          paddingBottom: "25px",
          backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[8] : "#fff",
        },
      })}
    >
      <Outlet />
    </AppShell>
  );
}
