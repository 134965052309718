import styled from "@emotion/styled";
import { TextInput } from "@mantine/core";

export const StyledInput = styled(TextInput)`
  max-width: 250px;
  width: 100%;
  input {
    padding-left: 35px;

    &:focus {
      border-width: 2px;
      padding-left: 36px;
    }
  }
`;
