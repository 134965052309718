import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CustomLogin, RequireAuth } from "@/auth";
import { Home } from "../Home";
import { Shell } from "./Shell";

export function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<CustomLogin />} />
        <Route path="/" element={<Shell />}>
          <Route
            index
            element={
              <RequireAuth key="home-authed">
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path="home"
            element={
              <RequireAuth key="home-authed-path">
                <Home />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
